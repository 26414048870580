/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.navicon-button').click(function(){
          $(this).toggleClass('open');
          $('.menu ul').toggleClass('show');
          // $('h1').addClass('fade');
        });

        $('.owl-carousel').owlCarousel({
            autoplay:true,
            autoplayTimeout:4000,
            autoplayHoverPause:true,
            responsive : {
                // breakpoint from 0 up
                0 : {
                    items : 1
                },
                // breakpoint from 768 up
                768 : {
                    items : 2
                },
                992 : {
                    items : 3
                }                
            }          
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contactus': {
      init: function() {
        // JavaScript to be fired on the about us page
          $(window).on('load', initMap);
      }
    },
    'stageandscreen': {
      init: function() {
          // JavaScript to be fired on the about us page
          $(window).on('load', function(){

            $('.client-list').isotope({
              layoutMode: 'fitRows',
              fitRows: {
                gutter:20
              },
              itemSelector: '.client-sort-box',
              percentPosition: true,
              getSortData: {
                category: '.writer',
                category: '.director',
                category: '.designer'
              },
              masonry: {
                columnWidth: '.grid-sizer'
              }     
            });

            $('.sort-by-button-group').on('click', 'a', function(e) {
              
              e.preventDefault();

              var sortValue = $(this).attr('data-filter');

              $('.client-list').isotope({ filter:sortValue });

            });

          });

      }
    },
  };

  function initMap() {

    // Specify features and elements to define styles.
    var styleArray = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
    
    mapOptions = {
      center: {lat: 51.539995, lng: -0.103054},
      scrollwheel: false,
      // Apply the map style array to the map.
      styles: styleArray,
      zoom: 15      
    }
    // Create a map object and specify the DOM element for display.
    var map = new google.maps.Map(document.getElementById('map'), mapOptions);

    var contentString = '<div id="content">'+
      '<div id="siteNotice">'+
      '</div>'+
      '<h3 id="firstHeading" class="firstHeading">rochelle stevens and co</h3>'+
      '<div id="bodyContent">'+
      '<p>' +
      '2 Terretts Place <br>' +
      'Upper Street <br>' +
      'London <br>' +
      'N1 1QZ </p>' +
      '</div>'+
      '</div>';    

    var infowindow = new google.maps.InfoWindow({
      content: contentString
    });

    var marker = new google.maps.Marker({
      position: mapOptions.center,
      map: map,
      title: 'rochelle stevens and co'
    });

    marker.addListener('click', function() {
      infowindow.open(map, marker);
    });

    google.maps.event.addDomListener(window, 'resize', function() {
        map.setCenter(mapOptions.center);
    });


  }  

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
